@use 'sass:color';
@use '@angular/material' as mat;
@use 'palette-helper';

//@import 'lib/good/sass/components/_variables.scss';
:root {
    --mdc-dialog-supporting-text-font: Inter, Helvetica, 'sans-serif';
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dir-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$dir-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dir-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dir-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $dir-primary,
            accent: $dir-accent,
            warn: $dir-warn
        )
    )
);

.card {
    --bs-card-bg: transparent;
}

/* You can add global styles to this file, and also import other style files */
//@import 'lib/good/sass/components/_variables.scss';
@import 'lib/good/sass/style.scss';
@import 'scss/svg-mixin';
@import 'scss/svg-background';

$dir-theme: palette-helper.modify-background($dir-theme, pink, color.scale($primary, $lightness: 0%));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dir-theme);

//@import 'bootstrap-icons/font/fonts/bootstrap-icons.scss';
//$bootstrap-icons-font-dir: 'bootstrap-icons/font/fonts'; //(for esbuilder)

@import 'bootstrap-icons/font/bootstrap-icons.scss';
//@import 'bootstrap-icons'; //(for esbuilder)

@import './scss/menu.scss';

@import 'ngx-toastr/toastr-bs5-alert';

.highlight-search {
    font-weight: bold;
}

.menu-item .menu-link,
.menu-item .menu-link .menu-title {
    transition: color 0.2s ease, background-color 0.2s ease;
}

html:not([data-bs-theme='dark']) {
    .background-overlay2 {
        background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.2) 100%),
            linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.2) 100%);

        &.size-cover {
            background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.2) 100%);
        }
    }

    .background-overlay {
        background: linear-gradient(to top, rgba($app-bg-color, 1) 0%, rgba($app-bg-color, 0.2) 100%),
            linear-gradient(to right, rgba($app-bg-color, 1) 0%, rgba($app-bg-color, 0.2) 100%);

        &.size-cover {
            background: linear-gradient(to top, rgba($app-bg-color, 1) 0%, rgba($app-bg-color, 0.2) 100%);
        }
    }
}

[data-bs-theme='dark'] {
    .background-overlay {
        background: linear-gradient(to top, rgba(21, 21, 33, 1) 0%, rgba(21, 21, 33, 0.2) 100%),
            linear-gradient(to right, rgba(21, 21, 33, 1) 0%, rgba(21, 21, 33, 0.2) 100%);

        &.size-cover {
            background: linear-gradient(to top, rgba(21, 21, 33, 1) 0%, rgba(21, 21, 33, 0.2) 100%);
        }
    }
}

// [data-theme="dark"] {
//     .background-overlay {
//         background: linear-gradient(to top, rgba(30, 30, 45, 1) 0%, rgba(30, 30, 45, 0.2) 100%), linear-gradient(to right, rgba(30, 30, 45, 1) 0%, rgba(30, 30, 45, 0.2) 100%);

//         &.size-cover {
//             background: linear-gradient(to top, rgba(30, 30, 45, 1) 0%, rgba(30, 30, 45, 0.2) 100%);
//         }
//     }
// }

//FLEX TO THE BOTTOM!
dir-directory,
dir-root,
dir-settings {
    display: flex;
    flex-direction: column;
    flex: 1;
}

//Tooltips fix
.tooltip {
    position: absolute;
}

.tooltip-arrow {
    position: absolute;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes fade-out {
    to {
        opacity: 0;
    }
}

@keyframes slide-from-right {
    from {
        transform: translateX(30px);
    }
}

@keyframes slide-to-left {
    to {
        transform: translateX(-30px);
    }
}

// ::view-transition-old(root) {
//     animation: 900ms cubic-bezier(0.4, 0, 1, 1) both fade-out, 3000ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
// }

// ::view-transition-new(root) {
//     animation: 2100ms cubic-bezier(0, 0, 0.2, 1) 900ms both fade-in,
//         3000ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
// }

::view-transition-old(avatar),
::view-transition-new(avatar) {
    animation: none;
    mix-blend-mode: soft-light;
}

::view-transition-image-pair(avatar) {
    isolation: none;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.modal-body {
    //transition: height 0.3s ease !important;
    //background: red !important;
}

//
// Root
//

[data-kt-app-layout='light-sidebar'] {
    &.app-default {
        background-color: var(--#{$prefix}app-light-sidebar-page-bg-color);
    }
}

[data-kt-app-layout='dark-sidebar'] {
    &.app-default {
        background-color: var(--#{$prefix}app-dark-sidebar-page-bg-color);
    }
}

[data-kt-app-layout='light-header'] {
    &.app-default {
        background-color: var(--#{$prefix}app-light-header-page-bg-color);
    }

    .card:not(.bg-gray-200) {
        background-color: var(--#{$prefix}app-light-header-page-card-bg-color);
        border: 1px dashed var(--#{$prefix}app-light-header-page-card-border-color);
    }
}

[data-kt-app-layout='dark-header'] {
    &.app-default {
        background-color: var(--#{$prefix}app-dark-header-page-bg-color);
    }

    .card:not(.bg-gray-200) {
        background-color: var(--#{$prefix}app-dark-header-page-card-bg-color);
        border: 1px dashed var(--#{$prefix}app-dark-header-page-card-border-color);
    }
}

[data-kt-app-layout='mini-sidebar'] {
    &.app-default {
        background-color: var(--#{$prefix}app-mini-sidebar-page-bg-color);
    }
}

[data-kt-app-layout='overlay'] {
    &.app-default {
        background-color: var(--#{$prefix}app-overlay-page-bg-color);
    }

    .card:not(.bg-gray-200) {
        background-color: var(--#{$prefix}app-overlay-page-card-bg-color);
        border: 1px dashed var(--#{$prefix}app-overlay-page-card-border-color);
    }
}

//
// Accordion
//

// Base
.accordion {
    // According heading
    .accordion-header {
        cursor: pointer;
    }

    // Icon toggle mode
    &.accordion-icon-toggle {
        // Accordion icon expaned mode
        .accordion-icon {
            display: flex;
            flex-shrink: 0;
            transition: $transition-base;
            transform: rotate(90deg);
            align-items: center;
            justify-content: center;

            i,
            .svg-icon {
                color: var(--#{$prefix}primary);
            }
        }

        // Accordion icon collapsed mode
        .collapsed {
            .accordion-icon {
                transition: $transition-base;
                transform: rotate(0);

                i,
                .svg-icon {
                    color: var(--#{$prefix}text-muted);
                }
            }
        }
    }

    // Icon collapse mode
    &.accordion-icon-collapse {
        // Accordion icon expaned mode
        .accordion-icon {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: $transition-base;

            .accordion-icon-on {
                display: inline-flex;
            }

            .accordion-icon-off {
                display: none;
            }

            i,
            .svg-icon {
                color: var(--#{$prefix}primary);
            }
        }

        // Accordion icon collapsed mode
        .collapsed {
            .accordion-icon {
                transition: $transition-base;

                .accordion-icon-on {
                    display: none;
                }

                .accordion-icon-off {
                    display: inline-flex;
                }

                i,
                .svg-icon {
                    color: var(--#{$prefix}text-muted);
                }
            }
        }
    }

    // Reset accordion item border
    &.accordion-borderless {
        // According item
        .accordion-item {
            border: 0;
        }
    }

    // Reset accordion item border, border radiues and background color
    &.accordion-flush {
        // According item
        .accordion-item {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

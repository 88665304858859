//
// Item Hover Custom
//

.item-border-hover {
    border: 1px solid transparent;
    border-radius: 6px;

    &:hover {
        border: 1px solid var(--#{$prefix}gray-200);
        cursor: pointer;
        transition: $transition-link;

        .label {
            background-color: var(--#{$prefix}primary) !important;
            transition: $transition-link;
            color: $white;
        }
    }

    .label {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        color: var(--#{$prefix}gray-500);
    }
}

//
// Symbol
//

@mixin symbol-size-svg($size) {
    > div.svg-background {
        width: $size;
        height: $size;
    }

    &.symbol-fixed {
        > div.svg-background {
            width: $size;
            height: $size;
            max-width: none;
        }
    }

    // Ratios
    &.symbol-2by3 {
        > div.svg-background {
            height: $size;
            width: $size * divide(3, 2);
            max-width: none;
        }
    }
}

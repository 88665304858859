//
// symbol
//

// Base
.symbol {
    // Image
    > div.svg-background {
        width: 100%;
        flex-shrink: 0;
        display: inline-block;
        @include border-radius($border-radius);
    }

    // Square
    &.symbol-square {
        > div.svg-background {
            border-radius: 0 !important;
        }
    }

    // Circle
    &.symbol-circle {
        > div.svg-background {
            border-radius: 50%;
        }
    }

    // Sizes
    @include symbol-size-svg(get($symbol-sizes, default));

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @each $name, $value in $symbol-sizes {
                @if ($name != 'default') {
                    &.symbol#{$infix}-#{$name} {
                        @include symbol-size-svg($value);
                    }
                }
            }
        }
    }
}

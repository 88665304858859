//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap base colors
$white: #ffffff;
$gray-100: #f5f8fa;
$gray-200: #eff2f5;
$gray-300: #e4e6ef;
$gray-400: #b5b5c3;
$gray-500: #a1a5b7;
$gray-600: #7e8299;
$gray-700: #5e6278;
$gray-800: #3f4254;
$gray-900: #181c32;

$gray-100-dark: #1b1b29;
$gray-200-dark: #2b2b40;
$gray-300-dark: #323248;
$gray-400-dark: #474761;
$gray-500-dark: #565674;
$gray-600-dark: #6d6d80;
$gray-700-dark: #92929f;
$gray-800-dark: #cdcdde;
$gray-900-dark: #ffffff;

// Theme colors
// Primary
$primary: #00b2ff;
$primary-active: #4ac9ff;
$primary-light: #ddf8fc;
$primary-light-dark: #2d3749;
$primary-inverse: #ffffff;

// Success
$success: #b8d935;
$success-active: #9fbe22;
$success-light: #f4fbdb;
$success-light-dark: #2d3e35;
$success-inverse: #ffffff;

// Info
$info: #4f55da;
$info-active: #383ebc;
$info-light: #eff0ff;
$info-light-dark: #382f59;
$info-inverse: #ffffff;

// Danger
$danger: #f06445;
$danger-active: #db5437;
$danger-light: #fff0ed;
$danger-light-dark: #452e36;
$danger-inverse: #ffffff;

// Warning
$warning: #e8c444;
$warning-active: #cfab2a;
$warning-light: #fdf4d6;
$warning-light-dark: #3f3a2b;
$warning-inverse: #ffffff;

// Border radii
$border-radius: 0.475rem;
$border-radius-sm: 0.325;

// Body
$body-bg: $white;
$body-bg-dark: #1e1e2d;

// Border
$border-dashed-color: $gray-300; // Custom variable
$border-dashed-color-dark: $gray-300-dark; // Custom variable
$border-color: $gray-200; // Custom variable
$border-color-dark: $gray-200-dark; // Custom variable

// Card
$card-border-color: $border-dashed-color;
$card-border-color-dark: $border-dashed-color-dark;
$card-bg: $body-bg;
$card-bg-dark: $body-bg-dark;

// Card new
$card-box-shadow: none;
$card-border-enabled: true;
$card-border-style: solid;
$card-border-radius: $border-radius;
$card-custom-bg: rgba(#f8f6f2, 0.3);
$card-custom-bg-dark: $body-bg-dark;
$card-custom-border-color: #e8e5dd;
$card-custom-border-color-dark: $gray-300-dark;

// Accordion
$accordion-border-radius: $border-radius;

// Button
$btn-border-radius-sm: $border-radius;

// Accordion
$accordion-border-radius: $card-border-radius;

// Modals
$modal-content-border-radius: $card-border-radius;

// Forms
$input-border-color: #efe9e1;
$input-border-color-dark: $gray-300-dark;

// Badge
$badge-border-radius: $border-radius-sm;

// Form
$input-placeholder-color: $gray-400;
$input-placeholder-color-dark: $gray-500-dark;
$input-btn-font-size: 1rem;

//
// sidebar Secondary
//

// General mode
.app-sidebar-secondary {
    transition: $app-sidebar-secondary-base-transition;
    position: relative;
    flex-shrink: 0;
    background-color: var(--#{$prefix}app-sidebar-secondary-base-bg-color);
    box-shadow: var(--#{$prefix}app-sidebar-secondary-base-box-shadow);
    border-left: var(--#{$prefix}app-sidebar-secondary-base-border-start);
    border-right: var(--#{$prefix}app-sidebar-secondary-base-border-end);
}

// Utilities
.app-sidebar-secondary-collapse-d-flex,
.app-sidebar-secondary-minimize-d-flex,
.app-sidebar-secondary-collapse-mobile-d-flex,
.app-sidebar-secondary-minimize-mobile-d-flex {
    display: none;
}

// Desktop mode
@include media-breakpoint-up(lg) {
    // Base
    .app-sidebar-secondary {
        @include property(z-index, $app-sidebar-secondary-base-z-index);
        @include property(margin-left, $app-sidebar-secondary-base-gap-start-mobile);
        @include property(margin-right, $app-sidebar-secondary-base-gap-end-mobile);
        @include property(margin-top, $app-sidebar-secondary-base-gap-top-mobile);
        @include property(margin-bottom, $app-sidebar-secondary-base-gap-bottom-mobile);
    }

    // Vars
    [data-kt-app-sidebar-secondary-enabled='true'] {
        --#{$prefix}app-sidebar-secondary-width-actual: calc(
            #{$app-sidebar-base-width} - #{$app-sidebar-primary-base-width} - #{$app-sidebar-primary-base-gap-start} - #{$app-sidebar-primary-base-gap-end} -
                #{$app-sidebar-secondary-base-gap-start} - #{$app-sidebar-secondary-base-gap-end}
        );
    }

    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-stacked='true'] {
        --#{$prefix}app-sidebar-secondary-width: calc(
            #{$app-sidebar-base-width} - #{$app-sidebar-primary-base-width} - #{$app-sidebar-primary-base-gap-start} - #{$app-sidebar-primary-base-gap-end} -
                #{$app-sidebar-secondary-base-gap-start} - #{$app-sidebar-secondary-base-gap-end}
        );

        --#{$prefix}app-sidebar-secondary-gap-start: #{$app-sidebar-secondary-base-gap-start};
        --#{$prefix}app-sidebar-secondary-gap-end: #{$app-sidebar-secondary-base-gap-end};
        --#{$prefix}app-sidebar-secondary-gap-top: #{$app-sidebar-secondary-base-gap-top};
        --#{$prefix}app-sidebar-secondary-gap-bottom: #{$app-sidebar-secondary-base-gap-bottom};
    }

    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-secondary-minimize='on'] {
        --#{$prefix}app-sidebar-secondary-width: #{$app-sidebar-secondary-minimize-width};

        --#{$prefix}app-sidebar-secondary-gap-start: #{$app-sidebar-secondary-minimize-gap-start};
        --#{$prefix}app-sidebar-secondary-gap-end: #{$app-sidebar-secondary-minimize-gap-end};
        --#{$prefix}app-sidebar-secondary-gap-top: #{$app-sidebar-secondary-minimize-gap-top};
        --#{$prefix}app-sidebar-secondary-gap-bottom: #{$app-sidebar-secondary-minimize-gap-bottom};
    }

    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-secondary-collapse='on'] {
        --#{$prefix}app-sidebar-secondary-width-actual: calc(
            #{$app-sidebar-base-width} - #{$app-sidebar-primary-base-width} - #{$app-sidebar-primary-base-gap-start} - #{$app-sidebar-primary-base-gap-end} -
                #{$app-sidebar-secondary-base-gap-start} - #{$app-sidebar-secondary-base-gap-end}
        );

        --#{$prefix}app-sidebar-secondary-width: 0px;
    }

    // States
    .app-sidebar-secondary {
        width: var(--#{$prefix}app-sidebar-secondary-width);

        [data-kt-app-sidebar-secondary-collapse='on'] & {
            transition: $app-sidebar-secondary-base-transition;
            width: var(--#{$prefix}app-sidebar-secondary-width-actual);
            margin-left: calc(-1 * var(--#{$prefix}app-sidebar-secondary-width-actual));
        }

        [data-kt-app-sidebar-secondary-minimize='on'] & {
            transition: $app-sidebar-secondary-base-transition;

            //background-color: var(--#{$prefix}app-sidebar-secondary-minimize-bg-color);
            //box-shadow: var(--#{$prefix}app-sidebar-secondary-minimize-box-shadow);
            //border-left: var(--#{$prefix}app-sidebar-secondary-minimize-border-start);
            //border-right: var(--#{$prefix}app-sidebar-secondary-minimize-border-end);

            @include property(margin-left, $app-sidebar-secondary-minimize-gap-start-mobile);
            @include property(margin-right, $app-sidebar-secondary-minimize-gap-end-mobile);
            @include property(margin-top, $app-sidebar-secondary-minimize-gap-top-mobile);
            @include property(margin-bottom, $app-sidebar-secondary-minimize-gap-bottom-mobile);
        }

        [data-kt-app-sidebar-secondary-hoverable='true'] & {
            .app-sidebar-secondary-hoverable {
                width: var(--#{$prefix}app-sidebar-secondary-width-actual);
            }
        }

        [data-kt-app-sidebar-secondary-hoverable='true'][data-kt-app-sidebar-secondary-minimize='on']
            &:hover:not(.animating) {
            transition: $app-sidebar-secondary-base-transition;
            width: var(--#{$prefix}app-sidebar-secondary-width-actual);
            box-shadow: var(--#{$prefix}app-sidebar-secondary-minimize-hover-box-shadow);
        }
    }

    // Integration
    .app-sidebar-secondary {
        // Header
        [data-kt-app-sidebar-fixed='true'][data-kt-app-header-fixed='true'][data-kt-app-sidebar-secondary-below-header='true']
            & {
            top: var(--#{$prefix}app-header-height);
        }

        // Toolbar
        [data-kt-app-sidebar-fixed='true'][data-kt-app-header-fixed='true'][data-kt-app-toolbar-fixed='true'][data-kt-app-sidebar-secondary-below-toolbar='true']
            & {
            top: calc(var(--#{$prefix}app-header-height) + var(--#{$prefix}app-toolbar-height, 0));
        }
    }

    // Utilities
    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-secondary-minimize='on'] {
        .app-sidebar-secondary-minimize-d-none {
            display: none !important;
        }

        .app-sidebar-secondary-minimize-d-flex {
            display: flex !important;
        }
    }

    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-secondary-collapse='on'] {
        .app-sidebar-secondary-collapse-d-none {
            display: none !important;
        }

        .app-sidebar-secondary-collapse-d-flex {
            display: flex !important;
        }
    }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    // Base
    .app-sidebar-secondary {
        @include property(z-index, $app-sidebar-secondary-base-z-index-mobile);
        @include property(margin-left, $app-sidebar-secondary-base-gap-start-mobile);
        @include property(margin-right, $app-sidebar-secondary-base-gap-end-mobile);
        @include property(margin-top, $app-sidebar-secondary-base-gap-top-mobile);
        @include property(margin-bottom, $app-sidebar-secondary-base-gap-bottom-mobile);
    }

    // Vars
    [data-kt-app-sidebar-secondary-enabled='true'] {
        --#{$prefix}app-sidebar-secondary-width-actual: calc(
            #{$app-sidebar-base-width-mobile} - #{$app-sidebar-primary-base-width-mobile} - #{$app-sidebar-primary-base-gap-start-mobile} -
                #{$app-sidebar-primary-base-gap-end-mobile} - #{$app-sidebar-secondary-base-gap-start-mobile} - #{$app-sidebar-secondary-base-gap-end-mobile}
        );
    }

    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-stacked='true'] {
        --#{$prefix}app-sidebar-secondary-width: calc(
            #{$app-sidebar-base-width-mobile} - #{$app-sidebar-primary-base-width-mobile} - #{$app-sidebar-primary-base-gap-start-mobile} -
                #{$app-sidebar-primary-base-gap-end-mobile} - #{$app-sidebar-secondary-base-gap-start-mobile} - #{$app-sidebar-secondary-base-gap-end-mobile}
        );

        --#{$prefix}app-sidebar-secondary-gap-start: #{$app-sidebar-secondary-base-gap-start-mobile};
        --#{$prefix}app-sidebar-secondary-gap-end: #{$app-sidebar-secondary-base-gap-end-mobile};
        --#{$prefix}app-sidebar-secondary-gap-top: #{$app-sidebar-secondary-base-gap-top-mobile};
        --#{$prefix}app-sidebar-secondary-gap-bottom: #{$app-sidebar-secondary-base-gap-bottom-mobile};
    }

    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-secondary-minimize-mobile='on'] {
        --#{$prefix}app-sidebar-secondary-width: #{$app-sidebar-secondary-minimize-width-mobile};

        --#{$prefix}app-sidebar-secondary-gap-start: #{$app-sidebar-secondary-minimize-gap-start-mobile};
        --#{$prefix}app-sidebar-secondary-gap-end: #{$app-sidebar-secondary-minimize-gap-end-mobile};
        --#{$prefix}app-sidebar-secondary-gap-top: #{$app-sidebar-secondary-minimize-gap-top-mobile};
        --#{$prefix}app-sidebar-secondary-gap-bottom: #{$app-sidebar-secondary-minimize-gap-bottom-mobile};
    }

    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-secondary-collapse-mobile='on'] {
        --#{$prefix}app-sidebar-secondary-width-actual: calc(
            #{$app-sidebar-base-width-mobile} - #{$app-sidebar-primary-base-width-mobile} - #{$app-sidebar-primary-base-gap-start-mobile} -
                #{$app-sidebar-primary-base-gap-end-mobile} - #{$app-sidebar-secondary-base-gap-start-mobile} - #{$app-sidebar-secondary-base-gap-end-mobile}
        );

        --#{$prefix}app-sidebar-secondary-width: 0px;
    }

    // States
    .app-sidebar-secondary {
        width: var(--#{$prefix}app-sidebar-secondary-width);

        [data-kt-app-sidebar-secondary-collapse-mobile='on'] & {
            transition: $app-sidebar-secondary-base-transition;
            width: var(--#{$prefix}app-sidebar-secondary-width-actual);
            margin-left: calc(-1 * var(--#{$prefix}app-sidebar-secondary-width-actual));
        }

        [data-kt-app-sidebar-secondary-minimize-mobile='on'] & {
            transition: $app-sidebar-secondary-base-transition;
            background-color: var(--#{$prefix}app-sidebar-secondary-minimize-bg-color-mobile);
            box-shadow: var(--#{$prefix}app-sidebar-secondary-minimize-box-shadow-mobile);
            border-left: var(--#{$prefix}app-sidebar-secondary-minimize-border-start-mobile);
            border-right: var(--#{$prefix}app-sidebar-secondary-minimize-border-end-mobile);

            //@include property( margin-left, $app-sidebar-secondary-minimize-gap-start-mobile);
            //@include property( margin-right, $app-sidebar-secondary-minimize-gap-end-mobile);
            //@include property( margin-top, $app-sidebar-secondary-minimize-gap-top-mobile);
            //@include property( margin-bottom, $app-sidebar-secondary-minimize-gap-bottom-mobile);
        }

        [data-kt-app-sidebar-secondary-hoverable-mobile='true'] & {
            .app-sidebar-secondary-hoverable {
                width: var(--#{$prefix}app-sidebar-secondary-width-actual);
            }
        }

        [data-kt-app-sidebar-secondary-hoverable-mobile='true'][data-kt-app-sidebar-secondary-minimize-mobile='on']
            &:hover:not(.animating) {
            transition: $app-sidebar-secondary-base-transition;
            width: var(--#{$prefix}app-sidebar-secondary-width-actual);
            box-shadow: var(--#{$prefix}app-sidebar-secondary-minimize-hover-box-shadow-mobile);
        }
    }

    // Utilities
    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-secondary-minimize-mobile='on'] {
        .app-sidebar-secondary-minimize-mobile-d-none {
            display: none !important;
        }

        .app-sidebar-secondary-minimize-mobile-d-flex {
            display: flex !important;
        }
    }

    [data-kt-app-sidebar-secondary-enabled='true'][data-kt-app-sidebar-secondary-collapse='on'] {
        .app-sidebar-secondary-collapse-mobile-d-none {
            display: none !important;
        }

        .app-sidebar-secondary-collapse-mobile-d-flex {
            display: flex !important;
        }
    }
}

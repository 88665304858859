.menu-item .menu-link {
    padding-top: 0px;
    padding-bottom: 0px;
    .menu-title {
        font-size: 1.25rem;
    }
    .menu-title-sm {
        font-size: 1.1rem;
    }
}

[data-kt-app-layout='light-sidebar'],
[data-kt-app-layout='dark-sidebar'] {
    .app-sidebar-menu {
        .menu {
            > .menu-item {
                > .menu-link {
                    margin-top: 0.4rem;
                    margin-bottom: 0.4rem;

                    .menu-title {
                        font-size: 1.15rem;
                    }
                }
            }

            .menu-item:first-child {
                .menu-link {
                    padding-top: 0.275rem;
                }
            }
        }
    }
}
